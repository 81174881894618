import React, { useState } from "react";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UpdateSet from "./UpdateSet";
import SetDelete from "./Setdelete";
import { useNavigate } from "react-router-dom";
const Action = ({ set, fetchSets }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem
                    onClick={() => {
                        setOpenUpdate(true);
                        handleMenuClose();
                    }}
                >
                    Update
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate(`/set/${set?.id}`)
                    }}
                >
                    View hotels
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        navigate(`/set/update/${set?.id}`)
                    }}
                >
                   update this set Hotels
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setOpenDelete(true);
                        handleMenuClose();
                    }}
                    style={{ color: 'red' }}
                >
                    Delete
                </MenuItem>
            </Menu>
            
            {openUpdate && (
                <UpdateSet
                    open={openUpdate}
                    onClose={() => setOpenUpdate(false)}
                    set={set}
                    fetchSets={fetchSets}
                />
            )}
            {openDelete && (
                <SetDelete
                    open={openDelete}
                    onClose={() => setOpenDelete(false)}
                    set={set}
                    fetchSets={fetchSets}
                />
            )}
        </div>
    );
};

export default Action;
