import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { toast } from "react-toastify";
import ENV from "../../../environment";
import Spinner from "react-spinner-material";
import { useParams } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const headCells = [
        { id: "id", numeric: false, label: "ID" },
        { id: "staffName", numeric: false, label: "Staff Name" },
        { id: "activity", numeric: false, label: "Activity" },
        { id: "date", numeric: false, label: "Date" },
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function HotelStaffLogsTable() {
    const [data, setData] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("date");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [totalItems, setTotalItems] = React.useState(0);
    const { hotelid } = useParams();

    // Fetch data for the current page
    const getData = async () => {
        setLoading(true);
        try {
            const res = await axios.get(
                `${ENV.URL}/api/hdp/tts/adm/hotel/staff_logs/${hotelid}?page=${page + 1}&limit=${rowsPerPage}`,
                {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                        "client-id": ENV.CLIENTID,
                        "client-secret": ENV.SECRET,
                    },
                }
            );
            setLoading(false);
            setData(res.data.data.data); // Set only the current page data
            setTotalItems(parseInt(res.data.data.totalItems, 10)); // Use the correct totalItems count
        } catch (err) {
            setLoading(false);
            setError(true);
            toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "desc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage); // Update the page state, this will trigger getData
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when rows per page is changed
    };

    // Trigger data fetching when page or rowsPerPage changes
    React.useEffect(() => {
        getData();
    }, [hotelid, page, rowsPerPage]);

    return (
        <div className="container mt-3">
            <h3>{"Staff Logs"}</h3>
            {loading ? (
                <div style={{ marginTop: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
                </div>
            ) : (
                <Box sx={{ width: "100%", marginTop: "20px" }}>
                    {!error && data.length === 0 && (
                        <Alert variant="outlined" severity="warning">
                            {"No Data yet"}
                        </Alert>
                    )}
                    {error && (
                        <Alert variant="outlined" severity="error">
                            {"Could not get data"}
                        </Alert>
                    )}
                    {data.length >= 1 && (
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: 750, px: 2 }} aria-labelledby="tableTitle" size="medium">
                                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={data.length} />
                                    <TableBody>
                                        {stableSort(data, getComparator(order, orderBy))
                                            .map((row) => (
                                                <TableRow hover tabIndex={-1} key={row.id}>
                                                    <TableCell align="left">{row.id}</TableCell>
                                                    <TableCell align="left">{`${row.first_name} ${row.last_name}`}</TableCell>
                                                    <TableCell align="left">{row.activity}</TableCell>
                                                    <TableCell align="left">{new Date(row.date).toLocaleString()}</TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                count={totalItems} // Use totalItems for pagination
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    )}
                </Box>
            )}
        </div>
    );
}

export default React.memo(HotelStaffLogsTable);
