import React, { useState } from 'react';
import './index.css';
import { FormControlWrapper } from './style';
import { Box, Button, Fade, FormControl, Grid, Input, Modal } from '@mui/material';
import axios from 'axios';
import ENV from "../../environment";
import REACT_APP from "../../environment";

import ReadImg from './ReadImg';
import { Typography } from 'antd';
import * as Yup from "yup";
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { LoadingButton } from '@mui/lab';
import { Label } from '@mui/icons-material';
import { toast } from 'react-toastify';
const ProductItem = ({ product, quantity, onIncrease, onDecrease }) => (
    <div className="product-item">
        <ReadImg uuid={product?.uuid}></ReadImg>
        <p>{product.name}</p>
        <p>available {product.number_of_rooms}</p>
        <p>{product.price.toLocaleString()} UZS</p>available
        <div className="quantity-controls">
            <button onClick={onDecrease} disabled={quantity <= 0}>-</button>
            <span>{quantity}</span>
            <button disabled={quantity >= product.number_of_rooms} onClick={onIncrease}>+</button>
        </div>
    </div>
);



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const GrBooking = () => {
    const [cart, setCart] = useState({});
    const [total, setTotal] = useState(0);
    const [date, setDate] = useState(false);
    const [startdate, setstartdate] = useState("");
    const [enddate, setenddate] = useState("");
    const [products, setproduct] = useState([])
    const [productsby, setproductby] = useState([])
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = React.useState(true);
    const getData = (values) => {
        console.log(values)
        setLoading(true);
        axios
            .post(
                `${ENV.URL
                }/api/hdp/tts/adm/hotel/getgroupalldata`, {
                "start_date": `${values.start_date}`,
                "end_date": `${values.end_date}`,
                "id": 1
            },
                {
                    headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
                }
            )
            .then((response) => {
                console.log(response)
                setproduct(response.data.data.room_categories)
                setDate(true)
                setstartdate(values.start_date)
                setenddate(values.end_date)
            })
            .catch((err) => {
                setLoading(false);
            });

    };
    const handleIncrease = (product) => {
        const newCart = { ...cart };
        newCart[product.id] = (newCart[product.id] || 0) + 1;
        setCart(newCart);
        setTotal(total + product.price);
    };

    const handleDecrease = (product) => {
        if (cart[product.id] > 0) {
            const newCart = { ...cart };
            newCart[product.id] -= 1;
            setCart(newCart);
            setTotal(total - product.price);
        }
    };
    const validation = Yup.object({
        first_name: Yup.string().required("first name is required"),
        last_name: Yup.string().required("last name is required"),
        email: Yup.string().email().required("email is required"),
        phone: Yup.string().required("phone number is required"),
    });
    const validation2 = Yup.object({
        start_date: Yup.date().required("start date is required"),
        end_date: Yup.date().required("last name is required"),
    });
    const emptyData = { first_name: "", last_name: "", email: "", phone: "" };
    const emptyData2 = { start_date: "", end_date: "" };

    const onSubmit = async (values) => {
        const { first_name, last_name, phone, email } = values;


        const filteredProducts = productsby.filter(product => product.summ !== 0);


        const room_categories = filteredProducts.map(product => product.id);


        const rooms = filteredProducts.map(product => product.summ);

        const data = {
            first_name: first_name || values["first name"],
            last_name: last_name,
            phone: phone,
            email: email,
            room_categories: room_categories,
            rooms: rooms,
            start_date: startdate,
            end_date: enddate,
            type: 2,
            debit: 0,
            credit: 0,
            comments: ''
        };

        console.log(data);

        setLoading(true);
        await axios
            .post(`${ENV.URL}/api/hdp/tts/adm/hotel/creategrbooking`, data, {
                headers: { Authorization: sessionStorage.getItem("token"), "client-id": ENV.CLIENTID, "client-secret": ENV.SECRET },
            })
            .then((res) => {
                setLoading(false);
                toast.success("Created group booking");
                handleClose();
                window.location.reload()
            })
            .catch((err) => {
                setLoading(false);
                toast.error("notsuccess create group booking");
                console.log(err);
            });
    };
    const handleCartSummaryClick = () => {
        const cartSummary = products.map(product => ({
            id: product.id,
            summ: cart[product.id] || 0,
        }));
        console.log(cartSummary);
        setproductby(cartSummary)
        handleOpen()
    };

    return (
        <div>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Fade in={open}>
                            <Box sx={style} className="addhotelmodal">
                                <Formik initialValues={emptyData} validationSchema={validation} onSubmit={async (values) => {
                                    onSubmit(values);
                                    return new Promise((res) => setTimeout(res, 500));
                                }}>
                                    {({ values, errors }) => (
                                        <Form autoComplete="off" className="mt-4">
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} className="marginBottom">
                                                    <Field fullWidth label="First name" name="first_name" component={TextField} type="text" />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} className="marginBottom">
                                                <Field fullWidth name="last_name" label="Last name" autoComplete="last_name" component={TextField} />
                                            </Grid>
                                            <Grid item xs={12} className="marginBottom">
                                                <Field fullWidth name="email" label="Email" component={TextField} />
                                            </Grid>
                                            <Grid item xs={12} className="marginBottom">
                                                <Field fullWidth name="phone" label="Phone number" component={TextField} />
                                            </Grid>
                                            <div className="left_right">
                                                <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }} color="error">
                                                    Close
                                                </Button>
                                                {!loading ? (
                                                    <LoadingButton type='submit' loading variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                        Submit
                                                    </LoadingButton>
                                                ) : (
                                                    <Button type="submit" className="mt-5" variant="contained" sx={{ mt: 3, mb: 2 }} color="success">
                                                        Submit
                                                    </Button>
                                                )}
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Fade>
                    </Box>
                </Modal>
                <div>
                    <Box sx={{ marginBottom: "40px" }}>
                        <Formik initialValues={emptyData2} validationSchema={validation2} onSubmit={async (values) => {
                            getData(values);
                            return new Promise((res) => setTimeout(res, 500));
                        }} >
                            {({ values, errors }) => (
                                <Form autoComplete="off" className="mt-4">
                                    <FormControlWrapper>


                                        <div style={{ display: "flex", gap: "10rem" }}>
                                            <Grid item xs={12} sm={6}>
                                                <label>{("Start date")}</label>
                                                <Field size="small" fullWidth name="start_date" component={TextField} type="date" />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <label>{("End date")}</label>
                                                <Field size="small" fullWidth name="end_date" component={TextField} type="date" />
                                            </Grid>
                                        </div>


                                        <Button
                                            variant="contained"
                                            type='submit'
                                        >
                                            {("Submit")}
                                        </Button>
                                    </FormControlWrapper>

                                </Form>
                            )}
                        </Formik>
                    </Box>
                </div>

            </div>
            <div className="shopping-cart">
                <div className="product-grid">
                    {products.map(product => {
                        if (product.number_of_rooms > 0) return <ProductItem
                            key={product.id}
                            product={product}
                            quantity={cart[product.id] || 0}
                            onIncrease={() => handleIncrease(product)}
                            onDecrease={() => handleDecrease(product)}
                        />
                    })}
                </div>

                <div className="cart-summary" >
                    <p>Booking {Object.values(cart).reduce((a, b) => a + b, 0)} rooms</p>
                    <Button disabled={!date || cart.length==0} variant="contained" color="success" onClick={handleCartSummaryClick}>Create group booking</Button>
                    <p>{total.toLocaleString()} UZS</p>
                </div>
            </div>
        </div>
    );
}

export default GrBooking;
