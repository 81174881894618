import React,{useState, useEffect} from 'react'
import axios from 'axios';
import img from '../../assets/no_img.jpg'
import REACT_APP from '../../environment'


const ReadImg = (props) => {
    const [loading, setLoading] = useState(props?.uuid?.length ? true : false);
    const [uuid, setUuid] = useState(props?.uuid || null);
    const getImg = async () => {
      if (uuid !== null) {
        setLoading(true);
        await axios
          .get(`${REACT_APP.BOOKING_API_URL}/api/hdp/file/${uuid}`, {
            responseType: "blob",
            headers: {
              Authorization:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiaWF0IjoxNzE0Mzc5NzgwLCJleHAiOjg2NDE3MTQzNzk3ODB9.GfT_84R7a6s78gYH7eGltkl11nvi8yG5ivPFR9pFP0E",
              "client-id":
                "36f7274bb5f75fcd4b37037ab1b4e36024292cfc4b68babc596e57bc803f6a4c",
              "client-secret":
                "7MV-Qr}W5,YR<5&&4^nA/U8'?Blh<b`l&?C,BTo({(-9vI-}_dezJczH9&N?4fc;",
            },
          })
          .then((response) => {
            const reader = new FileReader();
            reader.onload = (event) => {
              if (
                response?.data?.type === "application/pdf" ||
                /image/.test(response.data.type)
              ) {
                window.document.getElementById(
                  props?.uuid
                ).src = `${event?.target?.result}`;
                setUuid(event?.target?.result);
                setLoading(false);
              } else {
                console.log("else");
              }
            };
            reader.readAsDataURL(response?.data);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    };
  
    useEffect(() => {
      getImg();
    }, [!uuid]);
    return (
      <img
      className="object-cover w-full rounded-t-lg imgSizCard"
      id={uuid}
      src={img}
      alt="Category room image"
    />
    )
  }
export default ReadImg
