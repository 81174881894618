import React from "react";
import Box from "@mui/material/Box";
import HotelTabs from "../components/Hotel/Tabs";
import HotelInfo from "../components/Hotel/Hotel-info/index";
import One_set_view from "../components/Set_hotels/Set_one.jsx";

export default function Set_one_view() {
    return (
        <Box sx={{ width: "100%" }}>
            <One_set_view />
        </Box>
    );
}
