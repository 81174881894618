import React from "react";
import { Modal, Box, Typography, Button, Grid } from "@mui/material";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import axios from "axios";
import { toast } from "react-toastify";
import REACT_APP from "../../../environment";

const validationSchema = Yup.object({
    brand_name: Yup.string().required("Set name is required"),
});

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

const UpdateSet = ({ open, onClose, set, fetchSets }) => {
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await axios.put(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/sets/${set.id}`,
                { brand_name: values.brand_name },
                {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                        "client-id": REACT_APP.CLIENTID,
                        "client-secret": REACT_APP.SECRET,
                    },
                }
            );
            fetchSets();
            toast.success("Brand name updated successfully");
            onClose();
        } catch (error) {
            console.error("Error updating set:", error);
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message); // Show specific error message from backend if available
            } else {
                toast.error("Failed to update set");
            }
        } finally {
            setSubmitting(false);
        }
    };

    // Render form only if `set` is defined
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ ...style, width: 400 }}>
                <Typography variant="h6" align="center">Edit Brand Name</Typography>
                {set && (
                    <Formik
                        initialValues={{ brand_name: set.brand_name || "" }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2} sx={{ mt: 2 }}>
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="brand_name"
                                            label="Brand Name"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifyContent="space-between">
                                        <Button onClick={onClose} variant="contained" color="error">Cancel</Button>
                                        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>Update</Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                )}
            </Box>
        </Modal>
    );
};

export default UpdateSet;
