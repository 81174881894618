import React from "react";
import Set_hotels from "../components/Set_hotels/Tabs";

const HotelPage = () => {
    return (
        <>
            <Set_hotels />
        </>
    );
};

export default HotelPage;
