import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import REACT_APP from "../../environment";
import Spinner from "../Company/Spinner";
import { toast } from "react-toastify";

export default function DeleteHotel({ id, setIsWaiting, handleMenuClose, getAllHotels }) {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [wait, setwait] = React.useState(false);
    const [waittext, setwaittext] = React.useState("");
    const [valueSpinner, setValueSpinner] = React.useState(0);

    const reactivated = async () => {
        try {
            setIsWaiting(true);
            setwait(true);
            setwaittext("deleting...");
            console.log(100);
            
            setValueSpinner(100);
            await axios.delete(
                `${REACT_APP.URL}/api/hdp/tts/adm/hotel/${id}`,
                { headers: { Authorization: sessionStorage.getItem("token"), "client-id": REACT_APP.CLIENTID, "client-secret": REACT_APP.SECRET } }
            );
            setwaittext("table is being updated");
            await getAllHotels().catch((e) => {
                console.log(e);
            });
            setIsWaiting(false);
            handleClose();
            toast.success("Success!", { autoClose: 2000 });
            handleMenuClose();
        } catch (err) {
            console.log(err);
            handleClose();
            setIsWaiting(false);
            toast.error(err?.response?.data?.errors[0]?.message || "Something went wrong");
        }
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>Delete hotel</Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Delete hotel</DialogTitle>
                {wait ? (
                    <Spinner value={valueSpinner} />
                ) : (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Delete this hotel on the HDP platform</DialogContentText>
                    </DialogContent>
                )}
                <DialogActions>
                    {wait ? (
                        <div style={{ width: "100%", color: "#777", fontSize: "0.8em", padding: "0 20px" }}>{waittext}</div>
                    ) : (
                        <>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={reactivated}>Delete</Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
