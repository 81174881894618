import React from "react";
import Hotel from "../components/Hotel";
import GrBooking from "../components/GrBooking";

const HotelGroupPage = () => {
    return (
        <>
            <GrBooking/>
        </>
    );
};

export default HotelGroupPage;
