import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
    Box, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Alert, TablePagination
} from "@mui/material";
import { MessageContext } from "./Context";
import Spinner from "react-spinner-material";
import Action from "./Action";
import EnhancedTableHead from "./EnhancedTableHead";
import { toast } from "react-toastify";
import REACT_APP from "../../../environment";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const SetTable = () => {
    const navigate = useNavigate();
    const [sets, setSets] = useState([]);
    const [message] = useContext(MessageContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);

    const fetchSets = () => {
        setLoading(true);
        axios
            .get(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/sets`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
                params: {
                    page: page + 1, // Convert zero-based page index to one-based for the API
                    limit: rowsPerPage,
                },
            })
            .then((res) => {
                const { data, totalItems } = res.data.data;
                setSets(data); // Set the hotel sets data
                setTotalItems(totalItems); // Set the total items count for pagination
                setLoading(false);
                setError(false);
            })
            .catch((err) => {
                console.error("Error fetching sets:", err);
                setError(true);
                setLoading(false);
                toast.error("Failed to fetch sets.");
            });
    };

    useEffect(() => {
        fetchSets();
    }, [message, page, rowsPerPage]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ width: "100%" }}>
            {loading ? (
                <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
            ) : error ? (
                <Alert severity="error">Failed to load sets. Please try again.</Alert>
            ) : (
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <TableContainer>
                        <Table>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {sets.map((set) => (
                                    <TableRow hover key={set.id}>
                                        <TableCell align="left">{set.id}</TableCell>
                                        <TableCell align="left">{set.brand_name}</TableCell>
                                        <TableCell align="left">{set.hotel_count}</TableCell>
                                        <TableCell align="left">
                                            <Button
                                                onClick={(e) => {
                                                    navigate(`/set/update/${set?.id}`)
                                                }}
                                            >
                                                manage
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Action
                                                set={set}
                                                id={set.id}
                                                fetchSets={fetchSets}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            )}
        </Box>
    );
};

export default SetTable;
