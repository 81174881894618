import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Typography, Button, Divider, TextField } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import ENV from "../../../environment";
import { useParams } from "react-router-dom";
import Spinner from "react-spinner-material";

const Set_update_hotels = () => {
  const { set_id } = useParams();
  const [setData, setSetData] = useState(null);
  const [freeHotels, setFreeHotels] = useState([]);
  const [setHotels, setSetHotels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [draggedHotel, setDraggedHotel] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchHotels = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${ENV.URL}/api/hdp/tts/adm/hotel/sets/${set_id}/hotels`,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "client-id": ENV.CLIENTID,
            "client-secret": ENV.SECRET,
          },
        }
      );
      const res2 = await axios.get(
        `${ENV.URL}/api/hdp/tts/adm/hotel/sets/${set_id}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "client-id": ENV.CLIENTID,
            "client-secret": ENV.SECRET,
          },
        }
      );
      const { set } = res2.data.data;
      setSetData(set)
      const { free_hotels, set_hotels } = res.data.data;
      setFreeHotels(free_hotels);
      setSetHotels(set_hotels);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Failed to fetch hotels");
    }
  };

  useEffect(() => {
    fetchHotels();
  }, [set_id]);

  const handleDragStart = (hotel, origin) => {
    setDraggedHotel({ hotel, origin });
  };

  const handleDrop = (target) => {
    if (draggedHotel) {
      const { hotel, origin } = draggedHotel;
      if (origin === "free" && target === "set") {
        setFreeHotels(freeHotels.filter((item) => item.id !== hotel.id));
        setSetHotels([...setHotels, hotel]);
      } else if (origin === "set" && target === "free") {
        setSetHotels(setHotels.filter((item) => item.id !== hotel.id));
        setFreeHotels([...freeHotels, hotel]);
      }
      setDraggedHotel(null);
    }
  };

  const handleSave = async () => {
    const hotelIds = setHotels.map((hotel) => hotel.id);
    try {
      await axios.put(
        `${ENV.URL}/api/hdp/tts/adm/hotel/sets/hotels`,
        {
          set_id,
          hotel_ids: hotelIds,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "client-id": ENV.CLIENTID,
            "client-secret": ENV.SECRET,
          },
        }
      );
      toast.success("Hotel assignments updated successfully");
    } catch (err) {
      toast.error("Failed to update hotel assignments");
    }
  };

  const filteredFreeHotels = freeHotels.filter((hotel) =>
    hotel.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box className="container mt-4">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "100px",
          }}
        >
          <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Assign Hotels to Set "{setData?.brand_name}"</Typography>
            <Divider sx={{ my: 2 }} />
            <Grid item xs={6}>
            <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
              <TextField
                placeholder="Search Free Hotels"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ flexGrow: 1 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSearchTerm("")}
                sx={{ minWidth: "80px" }}
              >
                Clear
              </Button>
            </Box>
          </Grid>
          </Grid>
          
          {/* Free Hotels Section with Search */}
          <Grid item xs={6}>
            <Paper
              elevation={3}
              sx={{ p: 2, minHeight: "600px", maxHeight: "600px", overflowY: "auto" }}
              onDragOver={(e) => e.preventDefault()}
              onDrop={() => handleDrop("free")}
            >
              <Typography variant="h6">Free Hotels</Typography>
              <Box
                sx={{
                  mt: 2,
                  maxHeight: "500px", // Inner scrollable area for hotels
                  overflowY: "auto"
                }}
              >
                {filteredFreeHotels.map((hotel) => (
                  <Box
                    key={hotel.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "2px solid #00a8a8",
                      borderRadius: 1,
                      p: 1,
                      mb: 1,
                      cursor: "pointer",
                    }}
                    draggable
                    onDragStart={() => handleDragStart(hotel, "free")}
                  >
                    {hotel.name}
                  </Box>
                ))}
                {filteredFreeHotels.length === 0 && (
                  <Typography variant="body2" color="textSecondary" align="center">
                    No available free hotels
                  </Typography>
                )}
              </Box>
            </Paper>
          </Grid>

          {/* Assigned Hotels Section */}
          <Grid item xs={6}>
            <Paper
              elevation={3}
              sx={{ p: 2, minHeight: "600px", maxHeight: "600px", overflowY: "auto" }}
              onDragOver={(e) => e.preventDefault()}
              onDrop={() => handleDrop("set")}
            >
              <Typography variant="h6">Assigned to Set</Typography>
              <Box
                sx={{
                  mt: 2,
                  maxHeight: "500px",
                  overflowY: "auto"
                }}
              >
                {setHotels.map((hotel) => (
                  <Box
                    key={hotel.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "2px solid #00a8a8",
                      borderRadius: 1,
                      p: 1,
                      mb: 1,
                      cursor: "pointer",
                    }}
                    draggable
                    onDragStart={() => handleDragStart(hotel, "set")}
                  >
                    {hotel.name}
                  </Box>
                ))}
                {setHotels.length === 0 && (
                  <Typography variant="body2" color="textSecondary" align="center">
                    No hotels assigned to this set
                  </Typography>
                )}
              </Box>
            </Paper>
          </Grid>

          {/* Save Button */}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSave} disabled={loading}>
              Save Assignments
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Set_update_hotels;
