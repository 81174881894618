import React, { useContext, useState } from "react";
import { Button, Modal, Box, Typography, Grid } from "@mui/material";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import axios from "axios";
import { toast } from "react-toastify";
import { MessageContext } from "./Context";
import REACT_APP from "../../../environment";


const validationSchema = Yup.object({
    brand_name: Yup.string().required("Set name is required"),
});

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
};

const AddSet = () => {
    const [, setMessage] = useContext(MessageContext);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await axios.post(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/sets`, { brand_name: values.brand_name }, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            });
            setMessage((prev) => !prev);
            toast.success("Set created successfully");
            handleClose();
        } catch (error) {
            console.error("Error creating set:", error);
            toast.error("Failed to create set");
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div>
            <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
                <Button variant="contained"  style={{margin:"10px"}} color="primary" onClick={handleOpen}>
                    Add Set
                </Button>
            </Grid>
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Typography variant="h6" align="center" gutterBottom>
                        Add New Set
                    </Typography>
                    <Formik
                        initialValues={{ brand_name: "" }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2} sx={{ mt: 2 }}>
                                    <Grid item xs={12}>
                                        <Field
                                            component={TextField}
                                            name="brand_name"
                                            label="Set Name"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifyContent="space-between">
                                        <Button onClick={handleClose} variant="contained" color="error">
                                            Cancel
                                        </Button>
                                        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                            Create
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </Modal>
        </div>
    );
};

export default AddSet;
