const NODE_ENV = process.env.REACT_APP_ENV || "production";
// scp -r build root@159.223.217.246:/var/www/tts-admin

const modes = {
    local: {
        URL: "http://188.166.84.192:9106",
        // URL: "http://localhost:9106",
        CLIENTID: "e061f080b13ea9242025fd5ce8fc0e8fcfba09aee32f6d1c103f40d5ff06139b",
        SECRET: "8E$<k2x(I]qXF<Uqm'ZhjQ$m94)Cxo</&D?c(jqtdqeB<Y}TORcs1Ky[t@=52x\\>",
        COMMON_API: "https://tts-common-api.herokuapp.com",
        BOOKING_API_URL: "http://209.38.144.18:1010",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
        LOGIN_URL: "https://idp.transoxania.dev/login?redirect_to=http://localhost:3000",
    },
    development: {
        URL: "http://188.166.84.192:9106",
        BOOKING_API_URL: "http://209.38.144.18:1010",
        CLIENTID: "e061f080b13ea9242025fd5ce8fc0e8fcfba09aee32f6d1c103f40d5ff06139b",
        SECRET: "8E$<k2x(I]qXF<Uqm'ZhjQ$m94)Cxo</&D?c(jqtdqeB<Y}TORcs1Ky[t@=52x\\>",
        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
        LOGIN_URL: "https://idp.transoxania.dev/login?redirect_to=tts-admin-dev",
    },
    production: {
        URL: "https://tts-admin-api.transoxania.uz",
        BOOKING_API_URL:"https://booking.karvonsaroyi.uz",
        CLIENTID: "e061f080b13ea9242025fd5ce8fc0e8fcfba09aee32f6d1c103f40d5ff06139b",
        SECRET: "8E$<k2x(I]qXF<Uqm'ZhjQ$m94)Cxo</&D?c(jqtdqeB<Y}TORcs1Ky[t@=52x\\>",
        COMMON_API: "https://tts-common-api.herokuapp.com",
        COMMON_ID: "74a7a67458d77c3699a2db5132df8506afea37b31081987585d74724db98c1eb",
        COMMON_SECRET: 'I."b^34+Q>X\\"bHW.^9,6~=PtZsOn5F49kdWolPIlS=BW}9@N\\DGRxA9gNeHI}q{',
        LOGIN_URL: "https://idp.transoxania.dev/login?redirect_to=tts-admin-prod",
    },
};

export default modes[NODE_ENV];
