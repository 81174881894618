import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import REACT_APP from "../../../environment";

const Setdelete = ({ open, onClose, set, fetchSets }) => {
    const handleDelete = async () => {
        if (!set || !set.id) {
            console.error("Set object or set ID is undefined");
            toast.error("Invalid set data. Please try again.");
            return;
        }

        try {
            await axios.delete(`${REACT_APP.URL}/api/hdp/tts/adm/hotel/sets/${set.id}`, {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                    "client-id": REACT_APP.CLIENTID,
                    "client-secret": REACT_APP.SECRET,
                },
            });
            fetchSets();
            toast.success("Set deleted successfully");
            onClose();
        } catch (error) {
            console.error("Error deleting set:", error);
            toast.error("Failed to delete set");
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete Set</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the set "{set?.brand_name || 'Unknown'}"? This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Cancel</Button>
                <Button onClick={handleDelete} color="error">Delete</Button>
            </DialogActions>
        </Dialog>
    );
};

export default Setdelete;
