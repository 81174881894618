import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import ENV from "../../../environment";
import Spinner from "react-spinner-material";

const One_set_view = () => {
  const { set_id } = useParams();
  const [setData, setSetData] = useState(null);
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${ENV.URL}/api/hdp/tts/adm/hotel/sets/${set_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("token"),
          "client-id": ENV.CLIENTID,
          "client-secret": ENV.SECRET,
        },
      });
      const { set } = res.data.data;
      setSetData(set);
      setHotels(set.hotels); // Correctly setting hotels array from set data
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.errors[0]?.message || "An error occurred");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Helper function to convert type value to human-readable format
  const getTypeLabel = (type) => {
    switch (type) {
      case 1:
        return "Hotel";
      case 2:
        return "Hostel";
      case 3:
        return "Guest Home";
      default:
        return "Unknown";
    }
  };

  return (
    <div className="container mt-4">
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "100px" }}>
          <Spinner radius={150} color={"#1976d2"} stroke={16} visible={true} />
        </div>
      ) : (
        <>
          <Typography variant="h5" gutterBottom>Brand name: {setData?.brand_name}</Typography>
          <Typography variant="h6" className="mt-4">Associated Hotels</Typography>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Partner</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Abbreviation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hotels.length ? (
                  hotels.map((hotel) => (
                    <TableRow key={hotel.id}>
                      <TableCell>{hotel.id}</TableCell>
                      <TableCell>{hotel.name}</TableCell>
                      <TableCell>{hotel.email}</TableCell>
                      <TableCell>{hotel.status}</TableCell>
                      <TableCell>{hotel.partner}</TableCell>
                      <TableCell>{getTypeLabel(hotel.type)}</TableCell> {/* Convert type to label */}
                      <TableCell>{hotel.abbrivation}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No hotels associated with this set.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default One_set_view;
