import React, { useEffect, useState } from "react";
import Table from "./Table";
import AddSet from "./AddSet";
import MessageProvider from "./Context";
import { Box } from "@mui/material";

const SetManager = () => {
    return (
        <MessageProvider>
            <Box>
                <AddSet />
                <Table />
            </Box>
        </MessageProvider>
    );
};

export default SetManager;
