import React from "react";
import Box from "@mui/material/Box";
import HotelTabs from "../components/Hotel/Tabs";
import HotelInfo from "../components/Hotel/Hotel-info/index";
import Set_update_one_hotels from "../components/Set_hotels/Set_update_one_hotels/tabs.jsx";

export default function Set_update_hotels() {
    return (
        <Box sx={{ width: "100%" }}>
            <Set_update_one_hotels />
        </Box>
    );
}
