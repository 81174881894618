import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { Link, useLocation, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuIcon from "@mui/icons-material/Menu";

function samePageLinkNavigation(event) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                if (samePageLinkNavigation(event)) {
                    event.preventDefault();
                }
            }}
            aria-current={props.selected && "page"}
            {...props}
        />
    );
}

LinkTab.propTypes = {
    selected: PropTypes.bool,
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return { id: `simple-tab-${index}`, "aria-controls": `simple-tabpanel-${index}` };
}

function checkPage(page) {
    switch (page.split("/")[page.split("/").length - 1]) {
        case "category":
            return 2;
        case "room":
            return 3;
        case "images":
            return 4;
        case "policy":
            return 5;
        case "info":
            return 6;
        case "amenity":
            return 7;
        case "contact":
            return 8;
        case "staff_logs": 
            return 9;

        default:
            return 1;
    }
}

export default function HotelTabs() {
    const { hotelid } = useParams();
    const location = useLocation();
    const [value, setValue] = React.useState(checkPage(location.pathname));
    const hotelname = sessionStorage.getItem("hotelname");

    const handleChange = (event, newValue) => {
        if (event.type !== "click" || (event.type === "click" && samePageLinkNavigation(event))) {
            setValue(newValue);
        }
    };

    const [anchor, setAnchor] = React.useState(null);
    const openMenu = (event, elem) => {
        setAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchor(null);
    };

    return (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="nav tabs example" role="navigation">
                <Tab
                    component={Link}
                    label={
                        <div>
                            <ArrowBackIosNewIcon fontSize="small" /> Back
                        </div>
                    }
                    to="/hotel"
                    {...a11yProps(0)}
                />
                <Tab
                    label={
                        <div className="hotel-name-on-tab">
                            <HomeWorkIcon fontSize="small" /> {hotelname}
                        </div>
                    }
                    disabled
                    {...a11yProps(1)}
                />
                <React.Fragment>
                    <Tab className="hotel-tab-menu-pc-item" component={Link} label="Categories" to={`/hotel/${hotelid}/category`} {...a11yProps(2)} />
                    <Tab className="hotel-tab-menu-pc-item" component={Link} label="Rooms" to={`/hotel/${hotelid}/room`} {...a11yProps(3)} />
                    <Tab className="hotel-tab-menu-pc-item" component={Link} label="Images" to={`/hotel/${hotelid}/images`} {...a11yProps(4)} />
                    <Tab className="hotel-tab-menu-pc-item" component={Link} label="Policy" to={`/hotel/${hotelid}/policy`} {...a11yProps(5)} />
                    <Tab className="hotel-tab-menu-pc-item" component={Link} label="Info" to={`/hotel/${hotelid}/info`} {...a11yProps(6)} />
                    <Tab className="hotel-tab-menu-pc-item" component={Link} label="Amenities" to={`/hotel/${hotelid}/amenity`} {...a11yProps(7)} />
                    <Tab className="hotel-tab-menu-pc-item" component={Link} label="Contacts" to={`/hotel/${hotelid}/contact`} {...a11yProps(8)} />
                    <Tab className="hotel-tab-menu-pc-item" component={Link} label="Staff Logs" to={`/hotel/${hotelid}/staff_logs`} {...a11yProps(9)} /> {/* New Staff Logs Tab */}
                </React.Fragment>
                <div className="hotel-tab-menu-mb" {...a11yProps(40)}>
                    <IconButton style={{ borderRadius: "50%", width: "48px", height: "48px" }} onClick={openMenu}>
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        sx={{ mt: "23px" }}
                        id="menu-appbar"
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                        anchorEl={anchor}
                        open={Boolean(anchor)}
                        onClose={closeMenu}
                    >
                        <MenuItem>
                            <Tab component={Link} label="Categories" to={`/hotel/${hotelid}/category`} {...a11yProps(2)} />
                        </MenuItem>
                        <MenuItem>
                            <Tab component={Link} label="Rooms" to={`/hotel/${hotelid}/room`} {...a11yProps(3)} />
                        </MenuItem>
                        <MenuItem>
                            <Tab component={Link} label="Images" to={`/hotel/${hotelid}/images`} {...a11yProps(4)} />
                        </MenuItem>
                        <MenuItem>
                            <Tab component={Link} label="Policy" to={`/hotel/${hotelid}/policy`} {...a11yProps(5)} />
                        </MenuItem>
                        <MenuItem>
                            <Tab component={Link} label="Info" to={`/hotel/${hotelid}/info`} {...a11yProps(6)} />
                        </MenuItem>
                        <MenuItem>
                            <Tab component={Link} label="Amenities" to={`/hotel/${hotelid}/amenity`} {...a11yProps(7)} />
                        </MenuItem>
                        <MenuItem>
                            <Tab component={Link} label="Contacts" to={`/hotel/${hotelid}/contact`} {...a11yProps(8)} />
                        </MenuItem>
                        <MenuItem>
                            <Tab component={Link} label="Staff Logs" to={`/hotel/${hotelid}/staff_logs`} {...a11yProps(9)} /> {/* Staff Logs Tab in Menu */}
                        </MenuItem>
                    </Menu>
                </div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </Tabs>
        </Box>
    );
}
