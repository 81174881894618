import * as React from "react";
import HotelAmenities from "./Hotel";

export default function BasicTabs() {

    return (
           
                <HotelAmenities />
  
    );
}
